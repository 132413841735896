@use '../../styles/mixins' as mixins;

.animated-card-module {
    
    --iconSize: 175px;
    background-color: var(--wp--preset--color--bnpp-green-tint-15);
    padding: var(--wp--preset--spacing--60);
    color: var(--wp--preset--color--bnpp-black);
    position: relative;

    &:has(.icon-wrapper) {
        padding-bottom: var(--iconSize);
    }

    h3 {
        font-size: var(--wp--preset--font-size--heading-xs);
        color: var(--wp--preset--color--bnpp-green);
    }

    .bnpp-animated-card-module-text {
        margin-bottom: 0;
    }

    .icon-wrapper {
        width: var(--iconSize);
        position: absolute;
        bottom: 0;
        right: 0;
    }

}

a.animated-card-module {
    text-decoration: none;

        
    & {
        @include mixins.hoverKeyframes;
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        opacity: 0;
        border: solid 4px var(--wp--preset--color--bnpp-green);
        transition: 300ms;
    }

    &:hover {

        &::after {
            opacity: 1;
        }

        @include mixins.hoverAnimationsStart;

    }
}